<template>
  <div class="header">
    <div class="new_menu">
      <div class="logo">
        <a href="/homeadmin/dashboard">
          <img src="../assets/img/logo.png">
        </a>
      </div>
      <div style="width:400px;" class="right">
        <ul>
          <li @click="logoutProcess">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1" />
            </svg>로그아웃
          </li>
          <li class="name" style="cursor: auto;">
            {{ userNm }}님
          </li>
        </ul>
      </div>
      <div class="menu1">
        <ul>
          <router-link v-for="menu in menuData.depth1MenuList" :key="menu.menuId" tag="li" :to="menu.urlLinkTx" @click.native="e => fnOnClickMenu(e, menu.menuId, null)">
            <a :class="!$route.path.includes('/main') && !$route.path.includes('/mypage') && activeDepth1MenuId === menu.menuId ? ' active' : ''">{{ menu.menuNmTx }}</a>
            <ol class="sub_menu">
              <router-link v-for="subMenu in menuData.depth2MenuObj[menu.menuId]" :key="subMenu.menuId" tag="li" :to="subMenu.urlLinkTx" :class="activeDepth2MenuId === subMenu.menuId ? ' on' : ''"
                           @click.native="e => fnOnClickMenu(e, menu.menuId, subMenu.menuId)">
                {{ subMenu.menuNmTx }}
              </router-link>
            </ol>
          </router-link>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import EtnersCommonUtil from '@/common/etners.common.util'
import $ from 'jquery'

export default {
  created() {
  },
  mounted: function () {
    const vm = this
    const refs = this.$refs
    const userInfo = JSON.parse(sessionStorage.getItem('adminLoginUserData'))

    
    // 메뉴
    let $firstmenu = $('.menu1 > ul > li'), $header = $('.menu1')
    $firstmenu.mouseenter(function() {

      $header.addClass('.menubdn')
      $header.stop().animate({
        height: '340px'
      }, 200)
    }).mouseleave(function() {

      $header.stop().animate({
        height: '80px'
      }, 200, function() {

        // when completed
        $header.removeClass('menubdn')
      })
    })
  },
  methods: {
    // 1depth, 2depth 메뉴 클릭 이벤트
    fnOnClickMenu: function(e, activeDepth1MenuId, activeDepth2MenuId) {
      e.stopPropagation()
      this.activeDepth1MenuId = activeDepth1MenuId
      this.activeDepth2MenuId = activeDepth2MenuId
    },

    logoutProcess: function () {
      kendo.confirm('로그아웃하시겠습니까?').done(function () {
        sessionStorage.clear()
        setTimeout(function () {
          window.location.href = '/homeadmin/login'
        }, 300)
      })
    },
  },
  data: function () {
    /**********************************************************************
     * 메인 메뉴 관련 로직 구현분
     **********************************************************************/
    const sessionLoginUserData = sessionStorage.getItem('adminLoginUserData')

    let existLoginUserDataFl = false
    let loginUserObj
    let originalMenuData = []

    let userNm = ''
    let depth1MenuList
    let depth2MenuObj

    if (sessionLoginUserData) {
      loginUserObj = JSON.parse(sessionLoginUserData)
      originalMenuData = loginUserObj.menuMaster
      userNm = loginUserObj.userNmKoTx

      // depth1 메뉴 리스트 (중복 제거)
      depth1MenuList = [...new Map(
          originalMenuData.filter(menuItem => {
            return menuItem.superMenuId === null
          })
              .map(filterdMenuItem => {
                return [filterdMenuItem.menuId, filterdMenuItem]
              })).values()]

      // depth2 메뉴 key object
      depth2MenuObj = {}
      originalMenuData.filter(menuItem => {
        return menuItem.superMenuId !== 0
      })
          .forEach(filterdMenuItem => {
            if (depth2MenuObj[filterdMenuItem.superMenuId] === undefined) {
              depth2MenuObj[filterdMenuItem.superMenuId] = [filterdMenuItem]
            } else {
              depth2MenuObj[filterdMenuItem.superMenuId].push(filterdMenuItem)
            }
          })
    }
    existLoginUserDataFl = true

    return {
      userNm: userNm,
      loginUserObj: loginUserObj,
      menuData: {
        depth1MenuList,
        depth2MenuObj
      },
      activeDepth1MenuId: '',
      activeDepth2MenuId: '',
    }
  }

}
</script>
<style>
.new_menu .right {
  width: 15%;
}
.new_menu .menu1 ul>li {
  width: auto;
  font-size: 14px;
  padding: 0 30px;
  text-align: center;
}
.new_menu .menu1 ul>li>a:hover,
.new_menu .menu1 ul li>.on {
  border-bottom: 1px solid #333;
}
.new_menu .menu1 ul>li>.active {
  border-bottom: 1px solid #333;
}
.new_menu .menu1>ul>li .sub_menu li {
  text-align: left;
  font-size: 14px;
  white-space: nowrap;
  color: #fff;
  font-weight: 500;
}
</style>
