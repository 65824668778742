<template>
  <div id="wrap">
    <admin-main-header-area></admin-main-header-area>
    <router-view class="homeadmin_cont"></router-view>
    <vue-progress-bar></vue-progress-bar>
    <admin-main-footer-area></admin-main-footer-area>
  </div>
</template>

<script>

  import AdminMainHeaderArea from '../fragments/AdminMainHeaderArea'
  import AdminMainFooterArea from '../fragments/AdminMainFooterArea'

  export default {
    name      : 'AdminMainLayout',
    components: {
      AdminMainHeaderArea,
      AdminMainFooterArea
    },
  }
</script>
